import React from "react"
import Moment from "react-moment"

import Icon from "@mdi/react"
import { mdiMapMarkerStar, mdiOpenInNew } from "@mdi/js"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import EventWebsiteActive from "./EventWebsiteActive"
import EventAddressActive from "./EventAddressActive"

import "../../styles/event-card.css"

const EventCard = props => {
  const {
    id,
    eventDate,
    eventTime,
    eventVenueName,
    eventVenueAddress,
    eventVenueWebsite,
    eventPerformanceGroup,
  } = props

  const theme = useTheme()
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"))

  const nonNullField = field =>
    field !== null && field !== "" && field !== undefined ? true : false

  const websiteIcon = nonNullField(eventVenueWebsite) ? (
    <EventWebsiteActive website={eventVenueWebsite} />
  ) : (
    <div className="event-card-icon-wrapper-gray">
      <Icon
        className="event-card-link-icon"
        path={mdiOpenInNew}
        size={1.25}
        alt="No website."
      />
    </div>
  )

  const addressIcon = nonNullField(eventVenueAddress) ? (
    <EventAddressActive address={eventVenueAddress} />
  ) : (
    <div className="event-card-icon-wrapper-gray">
      <Icon
        className="event-card-link-icon"
        path={mdiMapMarkerStar}
        size={1.25}
        alt="No address."
      />
    </div>
  )

  return (
    <li className="event-card">
      <div className="event-card-main">
        <div className="event-card-date">
          {isSmScreen && (
            <>
              <p className="event-card-day">
                <Moment local format="ddd">
                  {eventDate}
                </Moment>
              </p>
              <p className="event-card-month">
                <Moment local format="MMM">
                  {eventDate}
                </Moment>
              </p>
            </>
          )}
          {isLgScreen && (
            <>
              <p className="event-card-day">
                <Moment local format="dddd">
                  {eventDate}
                </Moment>
              </p>
              <p className="event-card-month">
                <Moment local format="MMMM">
                  {eventDate}
                </Moment>
              </p>
            </>
          )}
          <h2 className="event-card-day-num">
            <Moment local format="D">
              {eventDate}
            </Moment>
          </h2>
          <p className="event-card-year">
            <Moment local format="YYYY">
              {eventDate}
            </Moment>
          </p>
        </div>
        <div className="event-card-infos">
          {nonNullField(eventTime) && (
            <span className="event-card-time">{eventTime}</span>
          )}
          {nonNullField(eventPerformanceGroup) && (
            <div className="event-card-performance-group">
              {eventPerformanceGroup}
            </div>
          )}
          <h2 className="event-card-venue-name">{eventVenueName}</h2>
          <div className="event-card-icon-bar">
            {websiteIcon}
            {addressIcon}
          </div>
        </div>
      </div>
    </li>
  )
}

export default EventCard
