import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Monetizer from "../components/monetizer"
import EventList from "../components/Event/EventList"
import StackedBlogList from "../components/Blog/StackedBlogList"
import AlbumCoversList from "../components/Album/AlbumCoversList"

import "../styles/landing.css"

const IndexPage = ({ data }) => {
  const theme = useTheme()
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const images = isLgScreen
    ? getImage(data.strapiLanding.landing_hero.localFile)
    : getImage(data.strapiLanding.landing_hero_cropped.localFile)

  return (
    <Layout>
      <SEO
        title="Home"
        description="Charlie Morris Website"
        image={data.strapiLanding.open_graph_image.localFile.publicURL}
        pathname={"/"}
      />
      <div className="landing-container">
        <div className="hero-container">
          <GatsbyImage
            className="landing-hero"
            image={images}
            alt="Charlie got da blues..."
            loading="eager"
            placeholder="dominantColor"
            objectFit="none"
            objectPosition="50% 0%"
          />
          {isLgScreen && (
            <div className="bio-blurb-container">
              <p>{data.strapiLanding.bio_blurb}</p>
              <Link to="/bio/">
                <div className="button-style">Read the Whole Story</div>
              </Link>
            </div>
          )}
        </div>
        <div className="mb-container">
          <div className="primary-container">
            <Monetizer />
            <div className="cards-container">
              <EventList
                title={data.strapiLanding.event_list_title}
                eventLimit={data.strapiLanding.event_list_limit}
              />
              <StackedBlogList
                title={data.strapiLanding.blog_post_list_title}
                blogLimit={data.strapiLanding.blog_post_list_limit}
              />
            </div>
          </div>
          <AlbumCoversList title={data.strapiLanding.album_list_title} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiLanding {
      bio_blurb
      event_list_title
      event_list_limit
      blog_post_list_title
      blog_post_list_limit
      album_list_title
      landing_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      landing_hero_cropped {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      open_graph_image {
        localFile {
          publicURL
        }
      }
    }
  }
`
export default IndexPage
