import React from "react"
import Icon from "@mdi/react"
import { mdiOpenInNew } from "@mdi/js"

const EventWebsiteActive = ({ website }) => {
  return (
    <a href={website} target="_blank" rel="noopener noreferrer">
      <div className="event-card-icon-wrapper">
        <Icon
          className="event-card-link-icon"
          path={mdiOpenInNew}
          size={1.25}
          alt="Go to venue website."
        />
      </div>
    </a>
  )
}

export default EventWebsiteActive
