import React from "react"
import Icon from "@mdi/react"
import { mdiMapMarkerStar } from "@mdi/js"

const EventAddressActive = ({ address }) => {
  const joinedAddress = address.split(" ").join("+")
  return (
    <a
      href={`http://maps.apple.com/?daddr=${joinedAddress}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="event-card-icon-wrapper">
        <Icon
          className="event-card-link-icon"
          path={mdiMapMarkerStar}
          size={1.25}
          alt="Go to venue website."
        />
      </div>
    </a>
  )
}

export default EventAddressActive
