import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Icon from "@mdi/react"
import { mdiLinkVariant } from "@mdi/js"

const AlbumCoversList = props => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAlbum(
        filter: { album_type: { eq: "audio" } }
        sort: { fields: release_date, order: DESC }
      ) {
        edges {
          node {
            front_cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 200, quality: 100)
                }
              }
            }
            title
            release_date
          }
        }
      }
    }
  `)

  const albumsList = data.allStrapiAlbum.edges
  const notAlphaNum = /\W/g
  const space = /\s/g

  const albumSlugs = albumsList.map(({ node }) =>
    node.title.replace(space, "_").replace(notAlphaNum, "")
  )

  return (
    <div className="section-container">
      <div className="section-title-line bg-grad-seafoam">
        <h1 className="section-title">{props.title}</h1>
        <div className="section-title-link">
          <Link to="/music/">
            <Icon
              className="section-link-icon"
              path={mdiLinkVariant}
              size={1.25}
              alt="Go to Stories page."
            />
          </Link>
        </div>
      </div>
      <ul className="ac-media-scroller snaps-inline">
        {albumsList.map(({ node }, index) => {
          return (
            <li key={index} className="ms-element">
              <Link to={`/audio/${albumSlugs[index]}`}>
                <GatsbyImage
                  className="album-covers-image"
                  image={getImage(node.front_cover.localFile)}
                  alt=""
                  loading="lazy"
                />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default AlbumCoversList
