import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import EventCard from "./EventCard"

const EventList = props => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiEvent(
        filter: { isFuture: { eq: true } }
        sort: { fields: date_only, order: ASC }
      ) {
        edges {
          node {
            id
            date_only
            time_alt
            start_dateTime
            end_dateTime
            venue_name
            venue_address
            venue_website_url
            performance_group
          }
        }
      }
    }
  `)

  const [eventList, setEventList] = useState([])
  const events = data.allStrapiEvent.edges
  const date = new Date()

  useEffect(() => {
    const currentEvents = events.filter(
      ({ node }) => node.date_only >= date.toISOString().split("T")[0] && node
    )
    setEventList(currentEvents)
  }, [])

  return (
    <div className="section-container">
      <h1 className="section-title-line section-title bg-grad-purple">
        {props.title}
      </h1>
      <ol className="card-list">
        {eventList.map(({ node }) => {
          console.log(node)
          return (
            <EventCard
              key={node.id}
              id={node.id}
              eventDate={node.date_only}
              eventTime={node.time_alt}
              eventStartDateTime={node.start_dateTime}
              eventEndDateTime={node.end_dateTime}
              eventVenueName={node.venue_name}
              eventVenueAddress={node.venue_address}
              eventVenueWebsite={node.venue_website_url}
              eventPerformanceGroup={node.performance_group}
            />
          )
        })}
      </ol>
    </div>
  )
}

export default EventList
