import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Icon from "@mdi/react"
import { mdiLinkVariant } from "@mdi/js"

import GridCard from "../GridCard"

const StackedBlogList = props => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogPost(sort: { fields: publish_date, order: DESC }) {
        edges {
          node {
            id
            title
            publish_date
            media {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    quality: 100
                    aspectRatio: 1.7778
                  )
                }
              }
            }
            blog_body
            excerpt
          }
        }
      }
    }
  `)

  const blogEdge = data.allStrapiBlogPost.edges
  const blogPosts = props.blogLimit
    ? blogEdge.slice(0, props.blogLimit)
    : blogEdge

  return (
    <div className="section-container">
      <div className="section-title-line bg-grad-red">
        <h1 className="section-title">{props.title}</h1>
        <div className="section-title-link">
          <Link to="/stories/">
            <Icon
              className="section-link-icon"
              path={mdiLinkVariant}
              size={1.25}
              alt="Go to Stories page."
            />
          </Link>
        </div>
      </div>
      <ol className="card-list">
        {blogPosts.map(({ node }, index) => {
          const notAlphaNum = /\W/g
          const space = /\s/g
          const slug = `stories/${node.title
            .replace(space, "_")
            .replace(notAlphaNum, "")}`
          return (
            <GridCard
              key={index}
              cardImage={node.media.localFile}
              cardTitle={node.title}
              cardDate={node.publish_date}
              cardExcerpt={node.excerpt}
              slug={slug}
            />
          )
        })}
      </ol>
    </div>
  )
}

export default StackedBlogList
